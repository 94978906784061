import styled, { css } from 'styled-components'

export const DraggableImageListStyled = styled('div')(
  ({ theme }) => css`
    .droppable-container {
      max-width: 100%;
      overflow-x: auto;
      display: -webkit-box;
      //row-gap: 16px;
      column-gap: 16px;
    }

    .droppable-container.dragging {
      background-color: ${theme.colors.success};
    }

    .draggable-item {
      align-self: stretch;
      display: grid;
      border: 1px solid ${theme.colors.grey700};
      padding: ${theme.spacing(1)};
      width: 260px;
    }

    .draggable-item.dragging {
      background-color: ${theme.colors.grey600};
    }
  `
)
