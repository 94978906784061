import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils'
import classNames from 'classnames'
import { Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { DraggableImageListStyled } from '@controls/DraggableImageList/DraggableImageList.styled'
import { Text } from '@controls/Text'

type Props = {
  title?: string
  imageUrls: ReadonlyArray<string>
  disabled: boolean
  onDragEnd: (result: DropResult) => void
  onDeleteImage: (index: number) => (confirmResult: boolean) => void
  additionalControls?: (index: number) => React.ReactNode
  renderImage?: (index: number, url: string) => React.ReactNode
}

export const DraggableImageList: React.FC<Props> = ({
  title,
  imageUrls,
  disabled,
  onDragEnd,
  onDeleteImage,
  additionalControls,
  renderImage
}) => {
  const { t } = useTranslation()

  return (
    <DraggableImageListStyled>
      {hasValue(title) && (
        <Text size="size-18" weight="regular">
          {title}
        </Text>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-images-list" direction="horizontal">
          {(provided, snapshot) => (
            <div
              className={classNames({ 'droppable-container': true, dragging: snapshot.isDraggingOver })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {imageUrls.map((url, i) => (
                <Draggable key={url} draggableId={url} index={i}>
                  {(provided, snapshot) => (
                    <Box
                      // TODO: These styles are already in styled component
                      alignSelf="stretch"
                      display="grid"
                      border={1}
                      borderColor="#B5B5B5"
                      p={1}
                      width={220}
                      ref={provided.innerRef}
                      className={classNames({ 'draggable-item': true, dragging: snapshot.isDragging })}
                      //style={provided.draggableProps.style}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {hasValue(renderImage) ? (
                        renderImage(i, url)
                      ) : (
                        <Box height={210}>
                          <img
                            src={url}
                            alt={`image-${i}`}
                            style={{ /*width: '100%', height: '100%', */ maxWidth: '200px', maxHeight: '200px' }}
                          />
                        </Box>
                      )}
                      <Box
                        alignSelf="self-end"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={2}
                        //width="100%"
                      >
                        {hasValue(additionalControls) && additionalControls(i)}
                        <ButtonWithConfirmation
                          color="error"
                          onConfirmResult={onDeleteImage(i)}
                          disabled={disabled}
                          confirmTitle={t('global.consent.delete.title')}
                          confirmMessage={t('global.consent.delete.message')}
                        >
                          <Delete />
                        </ButtonWithConfirmation>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </DraggableImageListStyled>
  )
}
